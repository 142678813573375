import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'inlineButton'>()((theme, _params, classes) => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		fontWeight: theme.weight.semibold,
		fontSize: 'inherit',
		gap: theme.spacing.xxsmall,
		height: 'auto',
		justifyContent: 'start',

		'&:hover': {
			background: 'none',
			textDecoration: 'none',
		},

		[`.${classes.inlineButton}&&`]: {
			padding: '0',
		},
	},
	inlineButton: {},
}));
