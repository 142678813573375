import { type GTM, type Search } from '@vakantiesnl/types';

import { mapOffer } from './mapOffer';
import { type Geo } from './useMapGeo';

export const accoEventValueConstructor = (
	acco: Search.FullAccommodationItem | Search.CompactAccommodationItem,
	geo?: Geo,
	offer?: Search.OfferItem | Search.AccommodationOnlyOffer,
	adlinkCount?: number,
	hasZooverDeal?: boolean,
): GTM.GTMEventV2['value'] => {
	return {
		type: acco.type,
		geo: {
			country_name: geo?.country_name ?? acco.country,
			region_name: geo?.region_name || acco.region,
			city_name: geo?.city_name || acco.city,
			country_code: acco.parents.find((parent) => parent.destinationType === 'COUNTRY')?.heliosId.toString(),
			region_code: acco.parents.find((parent) => parent.destinationType === 'REGION')?.heliosId.toString(),
			city_code: acco.parents.find((parent) => parent.destinationType === 'CITY')?.heliosId.toString(),
		},
		flash_deal: hasZooverDeal,
		id: acco.id,
		stars: acco.starRating,
		name: acco.name,
		parent_ids: {
			country_id: acco.parents.find((parent) => parent.destinationType === 'COUNTRY')?.heliosId.toString(),
			region_id: acco.parents.find((parent) => parent.destinationType === 'REGION')?.heliosId.toString(),
			city_id: acco.parents.find((parent) => parent.destinationType === 'CITY')?.heliosId.toString(),
		},
		adlink_count: adlinkCount,
		giata_id: acco.giataId,
		bookable: acco.isBookable || acco.isBookableQenner,
		photos_count: 'images' in acco ? acco.images.length : undefined,
		zooverAwards: acco.awards,
		...mapOffer(offer),
	};
};
